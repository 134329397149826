import styles from "./ScrollableContainer.less";
import theme from "./theme.less";

import React, { useState, useEffect } from "react";

import { matchSorter } from "match-sorter";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import DateRangeIcon from "@mui/icons-material/DateRange";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { Box } from "@material-ui/core";

import Modal from '@mui/material/Modal';

import axios from "axios";



// !!!!!!!!!!!!!!!
// Das Problem ist es wenn sich durch label + icao 2 idente Werte ergeben
// get airports from here https://gist.githubusercontent.com/tdreyno/4278655/raw/7b0762c09b519f40397e4c3e100b097d861f5588/airports.json
// filter out only name, code and icao here https://jsoneditoronline.org/
// replace "name" with "label" via IDE
// replace "" with "unknown" via IDE

export default function ComboBox() {
  let API_PATH = "https://oysterjets.com/php/";

  const [data, setData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [count, setCount] = useState(1);
  const [error, setError] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () =>  {
    setOpen(false);
    setEmailSent(false);
  }

  const [emailSent, setEmailSent] = useState(false);

  // const handleChangeAccordion =
  // (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
  //   setExpanded(expanded ? panel : false);
  // };

  // Constructs current Date for Date Time Picker
  // Adds a zero at the beginning if only one digit (months, days, hours, minutes)
  Number.prototype.zeroPad = function () {
    return ("0" + this).slice(-2);
  };
  const dateTime = new Date();
  const currentYear = dateTime.getFullYear();
  const currentMonth = (dateTime.getMonth() + 1).zeroPad();
  const currentDay = dateTime.getDate().zeroPad();
  const currentHours = dateTime.getHours().zeroPad();
  const currentMinutes = dateTime.getMinutes().zeroPad();
  const currentDateTime = `${currentYear}-${currentMonth}-${currentDay}T${currentHours}:${currentMinutes}`;


  const [values, setValues] = useState({
    airportDeparture: "",
    airportDestination: "",
    departureTime: currentDateTime,
	  pax: 1,
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    company: "",
    textarea: "",
    departureError: false,
    destinationError: false,
    departureTimeError: false,
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    mobileError: false,
    departureErrorText: "",
    destinationErrorText: "",
    departureTimeErrorText: "",
    firstNameErrorText: "",
    lastNameErrorText: "",
    emailErrorText: "",
    mobileErrorText: "",
  });


  const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
  };

  const getData = () => {
    fetch("../airports.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        // console.log(response)
        return response.json();
      })
      .then(function (myJson) {
        // console.log(myJson);
        setData(myJson);
      })
      .then(() => setLoaded(true));
  };
  useEffect(() => {
    getData();
  }, []);

  const IncNum = () => {
	setValues(prevState => ({
		...values,
		pax: values.pax + 1,
	}));
  };
  const DecNum = () => {
    if (values.pax > 2) {
		setValues(prevState => ({
			...values,
			pax: values.pax - 1,
		}));
	}
    else {
		setValues(prevState => ({
			...values,
			pax: 1,
		}));
    }
  };

const handleModalOpen = e => {

  if (
    values.airportDeparture !== "" &&
    values.airportDestination !== "" &&
    values.departureTime > currentDateTime
  ) {
    setOpen(true);
    setValues( prevValues => {
      return { ...prevValues,
        departureError: false,
        departureErrorText: "",
        destinationError: false,
        destinationErrorText: "",
        departureTimeError: false,
        departureTimeErrorText: ""
      }
    })
  }
  else {
    if (values.airportDeparture === "") {
      setValues( prevValues => {
        return { ...prevValues, departureError: true, departureErrorText: "Please fill out this field"}
      })
    } else {
      setValues( prevValues => {
        return { ...prevValues, departureError: false, departureErrorText: ""}
      })
    }
    if (values.airportDestination === "") {
      setValues( prevValues => {
        return { ...prevValues, destinationError: true, destinationErrorText: "Please fill out this field"}
      })
    } else {
      setValues( prevValues => {
        return { ...prevValues, destinationError: false, destinationErrorText: ""}
      })
    }
    if (values.departureTime <= currentDateTime) {
      setValues( prevValues => {
        return { ...prevValues, departureTimeError: true, departureTimeErrorText: "Please choose a valid Date"}
      })
    } else {
      setValues( prevValues => {
        return { ...prevValues, departureTimeError: false, departureTimeErrorText: ""}
      })
    }
  }
}

  const filterOptions = (options, { inputValue }) =>
    matchSorter(options, inputValue);

  const handleEmail = (e) => {
    if (
      values.firstName !== "" &&
      values.lastName !== "" &&
      values.email !== "" &&
      values.mobile !== ""
    ) {
    
    setValues( prevValues => {
      return { ...prevValues,
        firstNameError: false,
        firstNameErrorText: "",
        lastNameError: false,
        lastNameErrorText: "",
        emailError: false,
        emailErrorText: "",
        mobileError: false,
        mobileErrorText: ""
      }
    })
    
    setEmailSent(true);
    e.preventDefault();
    axios({
      method: "post",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data: stringdata,
    })
      .then((result) => {
        if (result.status === 200) {
      // setMailSent(true);
		  // setOpen(false);
		  setEmailSent(true);
		  setValues(prevState => ({
			...values,
			airportDeparture: "",
			airportDestination: "",
			departureTime: currentDateTime,
			pax: 1,
			firstName: "",
			lastName: "",
			email: "",
			mobile: "",
			company: "",
			textarea: "",
			firstNameError: false,
			lastNameError: false,
			emailError: false,
			mobileError: false,
			firstNameErrorText: "",
			lastNameErrorText: "",
			emailErrorText: "",
			mobileErrorText: "",
			}));
        } else {
          // setMailSent(false);
        }
        // console.log(result)
      })
      .catch((error) => setError(error.message));
    }
      else {
        if (values.firstName === "") {
          setValues( prevValues => {
            return { ...prevValues, firstNameError: true, firstNameErrorText: "Please fill out this field"}
          })
        } else {
          setValues( prevValues => {
            return { ...prevValues, firstNameError: false, firstNameErrorText: ""}
          })
        }
        if (values.lastName === "") {
          setValues( prevValues => {
            return { ...prevValues, lastNameError: true, lastNameErrorText: "Please fill out this field"}
          })
        } else {
          setValues( prevValues => {
            return { ...prevValues, lastNameError: false, lastNameErrorText: ""}
          })
        }
        if (values.email === "") {
          setValues( prevValues => {
            return { ...prevValues, emailError: true, emailErrorText: "Please fill out this field"}
          })
        } else {
          var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
          if (values.email.match(mailformat)){
            setValues( prevValues => {
              return { ...prevValues, emailError: false, emailErrorText: ""}
            })
          }
          else {
            setValues( prevValues => {
              return { ...prevValues, emailError: true, emailErrorText: "Please enter a valid email Address"}
            })
          }
        }
        if (values.mobile === "") {
          setValues( prevValues => {
            return { ...prevValues, mobileError: true, mobileErrorText: "Please fill out this field"}
          })
        } else {
          setValues( prevValues => {
            return { ...prevValues, mobileError: false, mobileErrorText: ""}
          })
        }
      }
  };

  const stringdata = JSON.stringify(values);
  

  const handleChangeDepartureTime = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValues({ ...values, departureTime: event.target.value });
  };

  const handleChangeFirstName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValues({ ...values, firstName: event.target.value });
  };

  const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, lastName: event.target.value });
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, email: event.target.value });
  };

  const handleChangeMobile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, mobile: event.target.value });
  };

  const handleChangeCompany = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, company: event.target.value });
  };

  const handleChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, textarea: event.target.value });
  };

  const handleChangePax = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, pax: event.target.value });
  };

  // useEffect(() => {
  //   if (
  //     values.airportDeparture !== "" &&
  //     values.airportDestination !== "" &&
  //     values.departureTime > currentDateTime
  //   ) {
  //     setExpanded(true);
  //   }
  // });

  // useEffect(() => {
  //   if (
  //     values.airportDeparture !== "" &&
  //     values.airportDestination !== "" &&
  //     values.departureTime > currentDateTime
  //   ) {
  //     setOpen(true);
  //   }
  // });

  return (
    <div>
      {data && data.length > 0 && loaded === true && (
        <div className="grid-container">
          {/* <p className="search-hint">Search by IATA, ICAO or AIRPORT NAME</p> */}
          <Grid container spacing={{ xs: 1, sm: 2, md: 4 }} id="first-container">
            <Grid item>
              <Autocomplete
                onChange={(event, value) =>
                  setValues({ ...values, airportDeparture: value })
                }
                value={values.airportDeparture}
                id="airportDeparture"
                filterOptions={filterOptions}
                disablePortal
                // id="autocomplete_departure"
                options={data
                  .map((option) => {
                    const firstLetter = option.label[0].toUpperCase();
                    return {
                      firstLetter: /[0-9]/.test(firstLetter)
                        ? "0-9"
                        : firstLetter,
                      ...option,
                    };
                  })
                  .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))
                  .map(
                    (option) =>
                      `${option.label !== "unknown" ? option.label : ""}${
                        option.code !== "unknown" ? " | " + option.code : ""
                      }${option.icao !== "unknown" ? " | " + option.icao : ""}`
                  )}
                sx={{ width: 250 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={values.departureError}
                    helperText={values.departureErrorText}
                    label="DEPARTURE"
                    placeholder="From ..."
                    // helperText="Search by Airport, ICAO or IATA"
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <FlightTakeoffIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item>
              <Autocomplete
                onChange={(event, value) =>
                  setValues({ ...values, airportDestination: value })
                }
                value={values.airportDestination}
                id="airportDestination"
                filterOptions={filterOptions}
                disablePortal
                options={data
                  .map((option) => {
                    const firstLetter = option.label[0].toUpperCase();
                    return {
                      firstLetter: /[0-9]/.test(firstLetter)
                        ? "0-9"
                        : firstLetter,
                      ...option,
                    };
                  })
                  .sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))
                  .map(
                    (option) =>
                      `${option.label !== "unknown" ? option.label : ""}${
                        option.code !== "unknown" ? " | " + option.code : ""
                      }${option.icao !== "unknown" ? " | " + option.icao : ""}`
                  )}
                sx={{ width: 250 }}
                renderInput={(params) => (
                  <TextField
                    error={values.destinationError}
                    helperText={values.destinationErrorText}
                    {...params}
                    label="DESTINATION"
                    placeholder="To ..."
                    // helperText="Search by Airport, ICAO or IATA"
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <FlightLandIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item>
              <Stack component="form" noValidate spacing={3}>
                <TextField
                  error={values.departureTimeError}
                  helperText={values.departureTimeErrorText}
                  variant="standard"
                  id="departureTime"
                  label="DEPARTURE TIME"
                  type="datetime-local"
                  defaultValue={values.departureTime}
                  name="departureTime"
                  onChange={handleChangeDepartureTime}
                  sx={{ width: 250 }}
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Stack>
            </Grid>

            <Grid item id="pax-item">
              <ButtonGroup variant="outlined" size="small">
                <Button onClick={DecNum}>-</Button>

          <TextField
				  style ={{width: '100%', textAlign: 'center'}}
                  id="pax"
                  label="NUMBER OF PASSENGERS"
                  InputProps={{ disableUnderline: true }}
				  onChange={handleChangePax}
                  value={values.pax + " PAX"}
                  variant="standard"
                />
                <Button onClick={IncNum}>+</Button>
              </ButtonGroup>

            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
                <Button
                  onClick={handleModalOpen}
                  id="button--send"
                  disableElevation
                  variant="contained"
                  size="large"
                  color="primary"
                >
                  <span style={{ textTransform: "none", marginLeft: "5px" }}>
                    {" "}
                    SEARCH FLIGHT{" "}
                  </span>{" "}
                </Button>
          </Grid>
        </div>
      )}

      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        
        <Box sx={style}>
        {emailSent === false &&
        <div>
        <Button onClick={handleClose} style={{float: "right", top: -10 , color: "#bfa25e", right: "-30px", width: "100% !important", display: "flex", justifyContent: "right !important"}}>X</Button>
        <div>
         
          <Typography variant="div" sx={{ width: "100%", flexShrink: 0 }}>
                  Contact Information
                </Typography>
          <Grid container spacing={{ xs: 1, sm: 2, md: 4 }}>
          
          <Grid item xs={12} sm={12} md={4}>
                  <TextField
            style ={{width: '100%'}}
                    error={values.firstNameError}
                    helperText={values.firstNameErrorText}
                    id="firstName"
                    label="FIRST NAME"
                    value={values.firstName}
                    onChange={handleChangeFirstName}
                    variant="standard"
                  />
          </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <TextField
            style ={{width: '100%'}}
                    error={values.lastNameError}
                    helperText={values.lastNameErrorText}
                    id="lastName"
                    label="LAST NAME"
                    value={values.lastName}
                    onChange={handleChangeLastName}
                    variant="standard"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <TextField
            style ={{width: '100%'}}
                    error={values.emailError}
                    id="email"
                    label="EMAIL"
                    value={values.email}
                    onChange={handleChangeEmail}
                    helperText={values.emailErrorText}
                    variant="standard"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <TextField
            style ={{width: '100%'}}
                    error={values.mobileError}
                    id="mobile"
                    label="MOBILE"
                    value={values.mobile}
                    onChange={handleChangeMobile}
                    helperText={values.mobileErrorText}
                    variant="standard"
                  />
                </Grid>
                
          <Grid item xs={12} sm={12} md={4}>
                  <TextField
            style ={{width: '100%'}}
                    error={false}
                    id="company"
                    label="COMPANY"
            helperText="(optional)"
                    value={values.company}
                    onChange={handleChangeCompany}
                    variant="standard"
                  />
                </Grid>

          <Grid item xs={12} sm={12} md={12}>
                  <TextField
            style ={{width: '100%'}}
                    error={false}
                    multiline
                    id="textarea"
                    label="MESSAGE"
                    helperText="(optional) Aircraft Preferences & Comments"
                    value={values.textarea}
                    onChange={handleChangeMessage}
                    variant="standard"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Button
                    onClick={handleEmail}
                    id="button--send"
                    disableElevation
                    variant="contained"
                    size="large"
                    color="primary"
                  >
                    <span style={{ textTransform: "none", marginLeft: "5px" }}>
                      {" "}
                      ASK FOR A QUOTE{" "}
                    </span>{" "}
                  </Button>
                </Grid>
          </Grid>
        </div>
        </div>
        }
        {emailSent === true &&
        <div>
          <Grid container spacing={{ xs: 1, sm: 2, md: 4 }} id="first-container">
            <Grid item>
            <Typography variant="p" component="p">
              Thank you. Your request was successfully sent. We will contact you soon.
            </Typography>
            </Grid>
          </Grid>
          <Button onClick={handleClose} style={{float: "right"}}>Close</Button>
        </div>

        }
        
        </Box>
        
      </Modal>
    </div>
  );
}
